<template>
    <el-button 
      :type="type" 
      :loading="loading" 
      :disabled="loading || disabled" 
      v-bind="rest" 
      @click="handleClick">
      <slot></slot>
    </el-button>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue';
  
  // 定义 props
  const props = defineProps({
    type: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rest: {
      type: Object,
      default: () => ({}),
    },
  });
  console.log(props,'props')
  // 定义 emits
  const emit = defineEmits(['click']);
  
  // 点击事件处理函数
  const handleClick = (event) => {
    if (!props.loading) {
      emit('click', event);
    }
  };
  </script>
  