import { createApp } from 'vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/css/global.css'
import router from '@/router/index.js'
import store from './store'
import ElementPlus from 'element-plus'
import { hasPerm } from '@/utils/permission'
import { listDictsByCode } from '@/api/sys/admin/dict'
import Pagination from '@/components/Pagination/index.vue'
import GlobalTable from '@/components/Table/GlobalTable/index.vue'
import NewGlobalTable from '@/components/Table/newGlobalTable/index.vue'
import FixedElTable from '@/components/Table/FixedElTable/index.vue'
import Upload from '@/components/Upload/index.vue'
import LoadingButton from '@/components/Loading/LoadingButton.vue'
import {sortSize} from '@/utils'
import JsBarcode from 'jsbarcode';
import Videojs from "video.js"
import 'video.js/dist/video-js.css';
import 'videojs-contrib-hls.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import mitt from 'mitt'
import {resizeFix} from '@/utils/resize'
import EgoImgView from '@/components/imgView'

import VuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
import TableDirective from '@/directive/tableDirective.js'

const emitter = mitt()
const app = createApp(App)
TableDirective(app)
resizeFix()
app.use(ElementPlus, {
  locale: zhCn,
  size: 'default'
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store)

app.config.globalProperties.listDictsByCode = listDictsByCode
app.config.globalProperties.$sortSize = sortSize
app.config.globalProperties.egoDefaultTime = [
  new Date(2000, 1, 1, 0, 0, 0),
  new Date(2000, 1, 1, 23, 59, 59),
]


app.use(router)
app.use(hasPerm)

app.component('Pagination', Pagination)
app.component('GlobalTable', GlobalTable)
app.component('NewGlobalTable', NewGlobalTable)
app.component('FixedElTable', FixedElTable)
app.component('Upload', Upload)
app.component('LoadingButton', LoadingButton)
app.component('vuePhotoZoomPro',VuePhotoZoomPro)
app.component('egoImgView', EgoImgView)


app.provide('emitter', emitter) // 注入provider
app.mount('#app')
